<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        From
        <chemical-latex content="AX4E_0" />
        to
        <chemical-latex content="AX3E1," />
        what is the change in geometry?
      </p>

      <p class="mb-2">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="4">
            <v-select
              v-model="inputs.AX4E0toAX3E1a"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select geometry:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>

          <v-col class="d-flex my-0 pb-0 pt-6 ml-6" cols="1"> to </v-col>

          <v-col class="d-flex my-0 pb-0 pl-0 ml-n3" cols="4">
            <v-select
              v-model="inputs.AX4E0toAX3E1b"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select geometry:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OleMiss116Lab1Prelab',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        AX4E0toAX3E1a: null,
        AX4E0toAX3E1b: null,
      },
      items: [
        {text: 'Bent', value: 'bent'},
        {text: 'Linear', value: 'linear'},
        {text: 'Octahedral', value: 'octahedral'},
        {text: 'See-saw', value: 'seesaw'},
        {text: 'Square planar', value: 'squarePlanar'},
        {text: 'Square pyramidal', value: 'squarePyramid'},
        {text: 'T-shaped', value: 'tshaped'},
        {text: 'Tetrahedral', value: 'tetrahedral'},
        {text: 'Trigonal bipyramidal', value: 'trigBipyramid'},
        {text: 'Trigonal planar', value: 'trigPlanar'},
        {text: 'Trigonal pyramidal', value: 'trigPyramid'},
      ],
    };
  },
};
</script>
<style scoped></style>
