var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-1"},[_vm._v(" From "),_c('chemical-latex',{attrs:{"content":"AX4E_0"}}),_vm._v(" to "),_c('chemical-latex',{attrs:{"content":"AX3E1,"}}),_vm._v(" what is the change in geometry? ")],1),_c('p',{staticClass:"mb-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex my-0 pb-0 pl-0 ml-0",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select geometry:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.AX4E0toAX3E1a),callback:function ($$v) {_vm.$set(_vm.inputs, "AX4E0toAX3E1a", $$v)},expression:"inputs.AX4E0toAX3E1a"}})],1),_c('v-col',{staticClass:"d-flex my-0 pb-0 pt-6 ml-6",attrs:{"cols":"1"}},[_vm._v(" to ")]),_c('v-col',{staticClass:"d-flex my-0 pb-0 pl-0 ml-n3",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select geometry:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.AX4E0toAX3E1b),callback:function ($$v) {_vm.$set(_vm.inputs, "AX4E0toAX3E1b", $$v)},expression:"inputs.AX4E0toAX3E1b"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }